<template>
  <footer class="mt-auto">
    <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
            <div class="col-6">
                <div class="fw-1" style="font-size: 1.1em;">Birgit Voss<br /></div>
                Skæring Egager 17<br />
                8250 Egå<br />
                <small>Bus 17 - under 5 minutters gåafstand</small>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <p>
                    <i class="bi bi-telephone"></i> 25 18 88 55<br />
                    <i class="bi bi-envelope"></i> <a href="mailto:birgit@vend.dk" target="_blank">birgit@vend.dk</a><br />
                    <!-- Åbningstider:<br />
                    (onsdag fra 8-16) -->
                </p>
            </div>
            </div>
          </div>
            
          <div class="col-lg-6 d-flex justify-content-center justify-content-lg-end">
              <p class="text-end">
                Kinesiolog RAB<br />
                Enhedsterapeut<br />
                Sygeplejerske<br />
                ICF certificeret Coach<br />
                Qigong instruktør<br />
              </p>
          </div>

        </div>
        <div class="row">
          <div class="col-6">
            Copyright &copy; Vend.dk {{new Date().getFullYear()}}
          </div> 
          <div class="col-6">
            <p class="text-end">CVR 37 95 86 38</p>
          </div>
        </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  bottom: 0;
  width: 100%;
  background-color: rgb(240, 248, 252);
  border-top: 1px solid rgb(211, 220, 230);
}
</style>