import { createRouter, createWebHistory } from 'vue-router'
import ProductRoutes from '@/pages/Products/routes.js'
import BlogRoutes from '@/pages/Blog/routes.js'
import auth from './auth'

export const routes = [
  { path: '/', name: 'home', component: () => import('@/pages/Home.vue') },
  { path: '/tilbud', component: () => import('@/pages/Products/Index.vue'), children: ProductRoutes },
  { path: '/profil', name: 'profile', title: 'Om Birgit', component: () => import('@/pages/Profile.vue') },
  { path: '/blog', component: () => import('@/pages/Blog/Index.vue'), children: BlogRoutes },
  ...auth
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})
